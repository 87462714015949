// i18next-extract-mark-ns-start markets-motorsport

import { MOTORSPORT_NAV, LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const MarketMotorsportPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<h2>Driving next generation sustainable motorsport</h2>

						<p>
							For many years, motorsport has been the development arena for a range of new innovations.
							Sustainable motorsport is no exception, from electric propulsion to energy recovery systems
							and now into hydrogen systems, next generation motorsport categories are defining the vehicle designs
							that will be seen on the consumer market in the coming period.
						</p>
						<p>
							However, innovation comes at a cost. With budget caps in place across many of the racing categories,
							teams and manufacturers must make their development processes as efficient as possible.
							A big area for process improvement is to use simulation software to answer the ‘What ifs’ before committing to expensive hardware prototyping.
							When developing new technology, the software can be used to refine the design in a cost-effective manner away from physical testing as far as possible.
						</p>
						<p>
							Our portfolio of simulation products is ready for the future demands of
							sustainable motorsport and are already being used in the design of cars
							to meet the 2026 regulations of Formula 1.
						</p>
						<p>
							Covering all aspects of design from weight reduction, new fuels,
							reduced friction and real time engine simulation,
							the Realis Simulation toolset is ready to help designers lead the field whatever the
							race series including cars, motorcycles, trucks and boats.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Solutions")}
						className="widget__sibling__pages">
						<AssetList>
							{MOTORSPORT_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>
		</Page>
	);
};

export default MarketMotorsportPage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(
			filter: { ns: { in: ["markets-motorsport", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
				ns
				data
					language
				}
			}
		}
		banner: file(relativePath: { eq: "content/markets/motorsport/Realis-Simulation_1915_MSport2_cropped.jpg" }) {
			...imageBreaker
		}
		resources: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { regex: "/resources/" }
				fields: { language: { eq: $language } }
				frontmatter: { markets: { in: "Motorsport" } }
			}
			sort: { fields: frontmatter___date, order: DESC }
			limit: 9
		) {
			nodes {
				fields {
					slug
				}
				frontmatter {
					title
					summary
					image {
						...imageStandard
					}
					type
				}
			}
		}
	}
`;
